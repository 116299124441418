import React , {Component} from 'react'
import Layout from '../layouts/index'
import DetailHeader from '../components/detailHeader/detailHeader'
import Projects from '../components/projects/projects'
import Seo from "../components/seo/seo"

class References extends Component {
    render() {
        const url = this.props.location.href;
        return (
            <Layout>
                <Seo title="Réferences" url={url}/>
                <DetailHeader title="Our projects"/>
                <Projects/>
            </Layout>
        );
    }
}

export default References;
