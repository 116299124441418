import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import './projects.scss'
import Img from "gatsby-image"

export default () => (
    <StaticQuery
        query={graphql`
        query{
            allNodeProjects {
            edges {
            node {
                id
                title 
                field_website_link 
                relationships {
                            field_media_img {
                    relationships {
                    field_media_image {
                        localFile {
                        childImageSharp {
                            fixed(width:362, height:300, quality:100) {
                                src
                            }
                        }
                        }
                    }
                    }
                }
                }
            }
            }
        }
        }
      `}
        render={data => (
            <div className="wd-projects">
                <div className="wd-projects__container">
                    {data.allNodeProjects.edges.map(({ node }) => (
                        <div key={node.id} className="wd-projects__project">
                            {node.relationships.field_media_img.relationships.field_media_image != null &&
                                <Img className="wd-projects__img" fixed={node.relationships.field_media_img.relationships.field_media_image.localFile.childImageSharp.fixed} />
                            }
                            <div className="wd-projects__link-box">
                                <a href={node.field_website_link} className=" wd-projects__link " rel="noopener noreferrer" target="_blank">
                                    {node.title} 
                                </a>
                            </div>
                        </div>   
                    ))}
                </div>
            </div>
        )}
    />
)

